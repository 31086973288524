import styled from "styled-components"
import Img from "gatsby-image"
import { Container } from "../../global"

export const Nav = styled.nav`
  position: relative;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    padding: 40px 0 0;
    position: ${({ position }) => position};
    width: 100%;
    top: 0;
    z-index: 1000;
    background: ${props => (props.scrolled ? `white` : null)};
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

      @media (max-width: ${props => props.theme.screen.md}) {
            display: none
      }
`

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 0 40px;
  }
`

export const Brand = styled.div`
  margin-top: 48px;
  margin-left: 16px;

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 0;

    .white {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`

export const ActionsContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screen.sm}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;

    p {
      ${({ theme }) => theme.font_size.regular};
      color: ${({ theme }) => theme.color.grey.grey04};
      text-align: right;
      margin-right: 10px;
    }
  }
`

export const StyledLogoImage = styled(Img)`
  width: 24px;
`
