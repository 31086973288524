import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "react-intl"
import Helmet from "react-helmet"
import { StringParam, withQueryParams } from "use-query-params"
import scrollTo from "gatsby-plugin-smoothscroll"
import LayoutPulsera from "../../components/common/layout/layoutPulsera"
import styled from "styled-components"
import Button from "../../components/common/button"
import SEO from "../../components/common/layout/seo"
import ICCheck from "../../images/svgs/checkpurple.svg"
import ICWarning from "../../images/svgs/warning.svg"
import Modal from "react-modal"

Modal.setAppElement(`#___gatsby`)
const modalStyles = {
    overlay: {
        zIndex: "1000",
        backgroundColor: "rgba(0, 0, 0, 0.58)",
    },
    content: {
        position: "relative",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        maxWidth: "960px",
        margin: "32px auto",
        border: 0,
    },
}


const SIZE = {
    KIDS: "KIDS",
    ADULTS: "ADULTS",
}

const PRICE_TYPE = {
    MONTHLY: "MONTHLY",
    ANNUAL: "ANNUAL",
}

const PRICE = {
    ANNUAL: "190€",
    MONTHLY: "19€",
    DEVICE: "0€",
}

const PRICE_ID = {
    KIDS: process.env.GATSBY_KIDS,
    ADULTS: process.env.GATSBY_ADULTS,
    MONTHLY: process.env.GATSBY_MONTHLY,
    ANNUAL: process.env.GATSBY_ANNUAL,
}

const Compra = ({ location, intl, query }) => {
    const SEOTitle = intl.formatMessage({ id: "SEO_title_buy_from" })
    const SEODescription = intl.formatMessage({ id: "SEO_description_buy_from" })
    const { purchase_method, userId, promotion_code } = query
    const size = SIZE.ADULTS;
    const [price, setPrice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(null)
    const [isModalScrolled, setIsModalScrolled] = useState(null)

    const images = useStaticQuery(graphql`
    query {
      header: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "pulsera_compra_durcal" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      backgroundBadged: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "background-badge-annual-price" }
      ) {
        childImageSharp {
          fluid(maxWidth: 48) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    function getCookie(name) {
        var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"))
        if (match) return match[2]
    }

    function openBrowserMoreInfo() {
        window.dataLayer.push({ "event": "moreInfoClick" })
        const url = intl.formatMessage({ id: "buy_more_info_url" })
        const evtObject = JSON.stringify({
            "name": "openBrowser",
            "url": url,
        })

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler &&
            window.webkit.messageHandlers.jsMessageHandler.postMessage) {
            window.webkit.messageHandlers.jsMessageHandler.postMessage(evtObject)
        } else if (window.JsMessageHandler && window.JsMessageHandler.publish) {
            window.JsMessageHandler.publish(evtObject)
        } else {
            window.open(url, "_blank")
        }
    }

    const onPayButtonClicked = () => {
        window.dataLayer.push({ "event": "payClick" })
        /*if (price && size && price === PRICE_TYPE.MONTHLY) {
            setModalOpen(true)
        } else {*/
            onSubmitPay()
        //}
    }

    const onSubmitPay = async () => {
        if (size && price) {
            setLoading(true)

            const data = {
                price: PRICE_ID[price],
                size: PRICE_ID[size],
                purchase_method: userId ? "app" : purchase_method ? purchase_method : "web",
                userId: userId || null,
                promotion_code: promotion_code || null,
            }

            const config = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "firstpromoter": getCookie("_fprom_tid"),
                },
                body: JSON.stringify(data),
            }

            const results = await fetch(
                `${process.env.GATSBY_API_URL}/web/stripe/checkoutSession`,
                config,
            )
            const urlToRedirect = await results.json()

            if (urlToRedirect.url) {
                window.location.assign(urlToRedirect.url)
            }
        }
    }

    function checkModalScrolled() {
        const elm = document.getElementById("modalText")
        const isScrolled = elm && Math.ceil(elm.scrollTop + elm.clientHeight) >= elm.scrollHeight
        if (isScrolled) {window.dataLayer.push({ "event": "payModalScrolled" });}
        setIsModalScrolled(isScrolled)
    }


    const onModalAcceptClick = () => {
        window.dataLayer.push({ "event": "payModalAccept" });
        onSubmitPay()
    }

    return (
        <>
            <SEO title={SEOTitle} description={SEODescription} />
            <Helmet>
                <script type="text/javascript">{`
          (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
          fpr("init", {cid:"wdxfm0r8"}); 
          fpr("click");
    `}</script>

                <script
                    src="https://cdn.firstpromoter.com/fpr.js"
                    type="text/javascript"
                    async
                ></script>
                <script>

                </script>
            </Helmet>
            <Modal
                isOpen={modalOpen || false}
                onRequestClose={() => setModalOpen(false)}
                style={modalStyles}
                contentLabel="Modal"
            >
                <h3 style={{textAlign: "center"}}>{intl.formatMessage({ id: "monthlyModalTitle" })}</h3>
                <div id="modalText" onScroll={checkModalScrolled}
                     style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}>

            <pre style={{ whiteSpace: "pre-wrap"}}>
              {intl.formatMessage({
                  id: "monthlyModalText",
              })}
            </pre>

                </div>
                <ButtonWrapper>
                    <Button id="gta_click_pay"
                            onClick={onModalAcceptClick}
                            btnType="coral"
                            rounded
                            fullwidth={true}
                            disabled={!isModalScrolled}
                    >
                        {intl.formatMessage({
                            id: "buy_page_button_accept",
                        })}
                    </Button>
                </ButtonWrapper>
            </Modal>
            <LayoutPulsera {...{ location }} layoutType="buy">
                <CompraWrapper>
                    <ImageWrapper>
                        <StyledImage fluid={images.header.childImageSharp.fluid} />
                    </ImageWrapper>
                    <ContentWrapper>
                        <h1>{intl.formatMessage({ id: "buy_page_title" })}</h1>
                        <h4>{intl.formatMessage({ id: "buy_page_title2" })}</h4>
                        <h2>{intl.formatMessage({ id: "buy_page_text" })}</h2>
                        <Subtitle>
                            <span>{intl.formatMessage({ id: "buy_page_text_description_title" })}</span>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description1" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description2" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description7" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description3" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description4" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description5" })}</p>
                            <p><ICCheck />{intl.formatMessage({ id: "buy_page_text_description6" })}</p>
                        </Subtitle>
                        <FormWrapper>
                            <FormSection id="price">
                                <h3>{intl.formatMessage({ id: "buy_page_subscription" })}</h3>
                                <OptionsSelection>
                                    <Option id="gta_click_price_month"
                                            active={size !== null}
                                            selected={price === PRICE_TYPE.MONTHLY}
                                            onClick={() => {
                                                window.dataLayer.push({ "event": "priceMonthClick" })
                                                if (size) {
                                                    setPrice(PRICE_TYPE.MONTHLY)
                                                    scrollTo("#pay")
                                                }
                                            }}
                                    >
                                        <div className="priceprice">
                                            <div className="priceprice description">
                                                <p className="titleplan">
                                                    {intl.formatMessage({
                                                        id: "buy_page_subscription_monthly_text",
                                                    })}
                                                </p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.MONTHLY} />
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_check1" })}
                                                </p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.MONTHLY} />
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_check2" })}
                                                </p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.MONTHLY} />
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_check3" })}
                                                </p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.MONTHLY} />
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_check4" })}
                                                </p>
                                                <p><SelectableICWarning active={size !== null}  selected={price === PRICE_TYPE.MONTHLY} />
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_check5" })}
                                                </p>
                                            </div>
                                            <div className="priceprice pricetext">
                                                <p className="pricetitle">
                                                    {PRICE.MONTHLY}{intl.formatMessage({
                                                    id: "buy_page_subscription_monthly_price",
                                                })}
                                                </p>
                                                <p>
                                                    {intl.formatMessage({ id: "buy_page_subscription_monthly_price_pay" })}
                                                </p>
                                            </div>
                                        </div>
                                    </Option>
                                    <Option id="gta_click_price_year"
                                            active={size !== null}
                                            selected={price === PRICE_TYPE.ANNUAL}
                                            onClick={() => {
                                                window.dataLayer.push({ "event": "priceYearClick" })
                                                if (size) {
                                                    setPrice(PRICE_TYPE.ANNUAL)
                                                    scrollTo("#pay")
                                                }
                                            }}
                                    >
                                        <BadgedWrapper>
                                            <StyledBadgedImage
                                                fluid={images.backgroundBadged.childImageSharp.fluid}
                                            />
                                            <div className="badged-copy-wrapper">
                                                <p className="badged-copy">
                                                    {intl.formatMessage({
                                                        id: "buy_page_subscription_annual_text_discount",
                                                    })}
                                                </p>
                                            </div>
                                        </BadgedWrapper>
                                        <div className="priceprice">
                                            <div className="priceprice description">
                                                <p className="titleplan">
                                                    {intl.formatMessage({
                                                        id: "buy_page_subscription_annual_text",
                                                    })}
                                                </p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.ANNUAL} />{intl.formatMessage({
                                                    id: "buy_page_subscription_annual_price_check1",
                                                })}</p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.ANNUAL} />{intl.formatMessage({
                                                    id: "buy_page_subscription_annual_price_check2",
                                                })}</p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.ANNUAL} />{intl.formatMessage({
                                                    id: "buy_page_subscription_annual_price_check3",
                                                })}</p>
                                                <p><SelectableICCheck active={size !== null}  selected={price === PRICE_TYPE.ANNUAL} />{intl.formatMessage({
                                                    id: "buy_page_subscription_annual_price_check4",
                                                })}</p>
                                            </div>
                                            <div className="priceprice pricetext">
                                                <p className="pricetitle">
                                                    {PRICE.ANNUAL}
                                                    {intl.formatMessage({ id: "buy_page_subscription_annual_price" })}
                                                </p>
                                                <p>
                                                    {intl.formatMessage({ id: "buy_page_subscription_annual_price_discount" })}
                                                </p>
                                            </div>
                                        </div>
                                    </Option>
                                </OptionsSelection>
                            </FormSection>
                        </FormWrapper>
                        <PaymentButtonWrapper id="pay">
                            <Text price={price} size={size}>
                                <h1>Total: {Number(PRICE[price]?.replace(/[^\d]/, "")) + Number(PRICE.DEVICE.replace(/[^\d]/, ""))}€</h1>
                                <p>Reloj GPS Durcal + {price !== null &&
                                intl.formatMessage({
                                    id:
                                        price === PRICE_TYPE.ANNUAL
                                            ? "buy_page_subscription_annual_footer_text"
                                            : "buy_page_subscription_monthly_footer_text",
                                })}</p>
                                <p>
                                    {intl.formatMessage({ id: "buy_page_footer" })}&nbsp;<a onClick={openBrowserMoreInfo}>
                                    {intl.formatMessage({ id: "buy_page_footer_here" })}</a>
                                </p>
                            </Text>
                            <ButtonWrapper>
                                <Button id="gta_click_pay"
                                        onClick={onPayButtonClicked}
                                        btnType="coral"
                                        rounded
                                        fullwidth={true}
                                        disabled={price === null || loading}
                                >
                                    {intl.formatMessage({
                                        id: "buy_page_button",
                                    })}
                                </Button>
                            </ButtonWrapper>
                        </PaymentButtonWrapper>
                    </ContentWrapper>
                </CompraWrapper>
            </LayoutPulsera>
        </>
    )
}

const SelectableICCheck = styled(ICCheck)`
  path {
    fill: ${(props) => props.active ? props.selected ? "#7066FF" : "#828187" : "#CDCCCF"}
  }

  margin-right: 15px;
`

const SelectableICWarning = styled(ICWarning)`
  path {
    fill: ${(props) => props.active ? props.selected ? "#7066FF" : "#828187" : "#CDCCCF"}
    
  }

  margin-right: 15px;
`

const ButtonWrapper = styled.div`
  width: 100%;
`

const Text = styled.div`
  display: ${props => props.price && props.size ? "block" : "none"};

  text-align: left;
  margin-bottom: 10px;

  p {
    margin: 0;
    font-family: ${({ theme }) => theme.font.medium};
    ${({ theme }) => theme.font_size.regular};
    font-weight: 400;
    color: ${({ theme }) => theme.color.grey.grey04};

    &:nth-child(2) {
      font-family: ${({ theme }) => theme.font.extraBold};
    }
  }
`

const StyledBadgedImage = styled(Img)`
  width: 48px;
`

const BadgedWrapper = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  top: -12px;
  right: -12px;

  .badged-copy-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    .badged-copy {
      margin: 0;
      ${({ theme }) => theme.font_size.small};
      line-height: 10px;
      font-family: ${({ theme }) => theme.font.medium};
      color: ${({ theme }) => theme.color.white};
      text-transform: uppercase;
      padding: 0 30px;
      text-align: center;
      transform: rotate(15deg);
    }
  }
`

const PaymentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${({ theme }) => theme.color.purple};
    text-decoration: underline;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    //flex-direction: row;
    //justify-content: space-between;
  }
`

const StyledFlagImage = styled(Img)`
  display: block;
  width: 48px;
`

const Subtitle = styled.div`
  margin-top: 32px;
  font-family: ${({ theme }) => theme.font.medium};
  ${({ theme }) => theme.font_size.medium};
  font-weight: 800;

  span {
    font-size: 22px;
  }

  svg {
    margin-right: 21px;
  }
`

const Option = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 48%;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    margin-bottom: 20px;
  }
  padding: 24px 0;
  align-items: center;
  text-align: left;
  cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};
  border: 1px solid ${({ theme, active, selected }) =>
    active
        ? selected
        ? theme.color.purple
        : theme.color.grey.grey04
        : theme.color.grey.grey02};
  border-radius: 11px;
  background-color: ${({ theme, active, selected }) =>
    active && selected ? `${theme.color.purple}1A` : "transparent"};

  p {
    margin: 8px 0 0 0;
    ${({ theme }) => theme.font_size.regular};
    color: ${({ theme, active, selected }) =>
    active
        ? selected
        ? theme.color.purple
        : theme.color.grey.grey04
        : theme.color.grey.grey02};
    font-family: ${({ theme }) => theme.font.extraBold};
  }

  .children {
    margin-top: 0;
    font-family: ${({ theme }) => theme.font.medium};
  }

  .device {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 12px 0 12px;
  }

  .device .description {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
  }

  .device .price {
    width: 50%;
    align-items: center;
    justify-content: flex-end;

    p {
      ${({ theme }) => theme.font_size.large};
      font-family: ${({ theme }) => theme.font.medium};
    }
  }

  .priceprice {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
      ${({ theme }) => theme.font_size.regular};
      font-family: ${({ theme }) => theme.font.medium};
    }
  }

  .priceprice .description {
    flex-direction: column;
    align-items: flex-start;
    //margin-left: 32px;
    margin-bottom: 24px;
    font-weight: 400;
    padding: 0 32px 0 32px;
  }

  .priceprice .pricetext {
    justify-content: flex-end;
    margin-bottom: 0;
    align-items: flex-end;
    padding: 0 15px 0 15px;
    margin-top: 10px;
    text-align: right;

    .pricetitle {
      ${({ theme }) => theme.font_size.large};
    }
  }

  .priceprice .titleplan {
    ${({ theme }) => theme.font_size.medium};
    font-weight: 800;
  }

  &:hover {
    border: 1px solid ${({ theme, active }) =>
    active ? theme.color.purple : theme.color.grey.grey02};
    background-color: ${({ theme, active }) =>
    active ? `${theme.color.purple}1A` : "transparent"};
  }
`

const OptionsSelection = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row;

  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
  }
`

const OptionsSelectionSize = styled(OptionsSelection)`
  flex-direction: column;

  .option_size {
    margin-bottom: 15px;
    width: 100%;
    align-items: flex-start;
  }
`


const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey.grey01};
  margin-top: 32px;

  h3 {
    ${({ theme }) => theme.font_size.regular};
    font-family: ${({ theme }) => theme.font.extraBold};
    margin-top: 0%;
  }

  &:last-child {
    border-bottom: none;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-family: ${({ theme }) => theme.font.extraBold};
    ${({ theme }) => theme.font_size.xlarge};
    margin-top: 0;
    margin-bottom: 24px;
    font-weight: 800;
  }

  h2 {
    margin: 0;
    font-family: ${({ theme }) => theme.font.medium};
    ${({ theme }) => theme.font_size.medium};
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-family: ${({ theme }) => theme.font.medium};
    ${({ theme }) => theme.font_size.larger};
    font-weight: 700;
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    h1 {
      ${({ theme }) => theme.font_size.xxlarge};
    }
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    h2 {
      ${({ theme }) => theme.font_size.large};
    }
  }

  @media (max-width: ${props => props.theme.screen.lg}) {
    h4 {
      ${({ theme }) => theme.font_size.large};
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 460px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-right: 40px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-right: 80px;
  }

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin-right: 124px;
  }

`

const CompraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 80px;
  max-width: 1440px;
  margin: 90px auto 0;
  align-items: center;

  @media (min-width: ${props => props.theme.screen.sm}) {
    padding: 0 80px 80px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px 80px;
    align-items: normal;
  }
`

export default withQueryParams(
    {
        purchase_method: StringParam,
        userId: StringParam,
        promotion_code: StringParam,
    },
    injectIntl(Compra),
)
