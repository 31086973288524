import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Nav, Brand, StyledContainer, ActionsContainer } from "./style"
import Logo from "../../../images/svgs/logo.svg"
import { checkLanguage, paths } from "../../../utils"
import Button from "../button"

const NavigationPulsera = ({ intl, layoutType, location }) => {
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Nav position={layoutType === "buy" ? "relative" : "fixed"}>
      <StyledContainer>
        <Brand>
          <Link to={`/`} onClick={goToTop}>
            <Logo alt="Durcal" id="LogoDurcal" />
          </Link>
        </Brand>

        {layoutType !== "buy" && (
          <ActionsContainer>
            <p>{intl.formatMessage({ id: "navigation_bar_text" })}</p>
            <Button
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].compra
              }`}
              btnType="coral"
              rounded
              external={false}
            >
              {intl.formatMessage({ id: "button_buy" })}
            </Button>
          </ActionsContainer>
        )}
      </StyledContainer>
    </Nav>
  )
}

export default injectIntl(NavigationPulsera)
